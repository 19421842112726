.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .footer-column {
    flex: 1;
    margin: 10px;
  }
  
  .footer-column h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 8px;
  }
  
  .footer-column ul li a {
    color: #ccc;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-column ul li a:hover {
    color: #fff;
  }
  
  .footer-social {
    margin-bottom: 20px;
  }
  
  .footer-social a {
    margin: 0 10px;
    color: #ccc;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-social a:hover {
    color: #fff;
  }
  
  .footer-bottom p {
    font-size: 14px;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .footer-links {
      flex-direction: column;
    }
  
    .footer-column {
      margin: 20px 0;
    }
  }
  