/* homepage.css */

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  background-color: #f9f9f9;


}

.product-search {
  width: 100%;
  max-width: 1400px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  margin-top: -45px;
  box-shadow: 0 4px 8px rgba(241, 165, 0, 0.692), 0 6px 20px rgba(3, 57, 235, 0.19);
  transform: perspective(1000px) rotateX(5deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 45px;
  /* position: fixed; */
}

.product-search:hover {
  transform: perspective(1000px) rotateX(0deg);
}

.search-bar {
  width: 100%;
  max-width: 1400px;
  padding: 10px 15px;
  border: 1px solid #fcfcfc;
  font-size: 13px;
  box-shadow: 0px 2px 6px rgba(228, 139, 5, 0.76);
  transition: box-shadow 0.3s ease;
  text-align: center;
}

.search-bar:focus {
  box-shadow: 0px 2px 8px rgba(124, 100, 22, 0.2);
}




/* Featured Products Section */
.featured-products {
  width: 100%;
  max-width: 1400px;
  margin: 10px auto;
  padding: 4px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title for Featured Products */
.featured-products h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

/* Decorative underline for the title */
.featured-products h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #e74c3c;
  margin: 10px auto 0;
  border-radius: 2px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: calc(25% - 20px);
  max-width: 320px;
  margin-bottom: 30px;
}

/* Product item container */
.product-item {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Hover effect for product item */
.product-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
}

/* Product image styling */
.product-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 15px 15px 0 0;
  transition: transform 0.3s ease;
}

/* Product info container */
.product-info {
  padding: 15px;
}

/* Product name styling */
.product-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

/* Product description styling */
.product-description {
  font-size: 11px;
  color: #666;
  margin-bottom: 10px;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

/* Product price styling */
.product-price {
  font-size: 20px;
  font-weight: 700;
  color: #e74c3c;
  margin-bottom: 5px;
}

.buy-button {
  background: #04ccdaf6;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.buy-button:hover {
  background: #155a8a;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .product-item {
    width: calc(33.333% - 20px);
  }



  .search-bar {
    max-width: 800px;
  }
}
@media (max-width: 1500px) {
  .product-item {
    width: calc(33.333% - 20px);
  }



  .search-bar {
    max-width: 1400px;
  }
}

@media (max-width: 768px) {
  .product-item {
    width: calc(50% - 20px);
  }


  .search-bar {
    max-width: 650px;
  }

  .product-description {
    font-size: 9px;
    -webkit-line-clamp: 3;
  }

  .product-name {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 10px;
  }
}

@media (max-width: 500px) {
  .product-item {
    width: calc(50% - 10px);
  }



  .search-bar {
    max-width: 99%;
    padding: 14px;
  }
  .product-description {
    font-size: 8px;
    -webkit-line-clamp: 2;
  }
  .product-name {
    font-size: 12px;
    font-weight: 800;
  }
  .product-info {
    padding: 1px;
  }
  .product-image {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border-radius: 15px 15px 0 0;
    transition: transform 0.3s ease;
  }
  .product-price {
    font-size: 15px;
    font-weight: 700;
    color: #e74c3c;
    margin-bottom: 5px;
  }
  .buy-button {
    background: #04ccdaf6;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s ease;
  }
}
