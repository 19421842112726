/* WhatsAppMessageLink.css */

button {
  /* background: linear-gradient(145deg, #FFC107, #04ccdaf6);  */

  border: none; /* Remove default border */
  border-radius: 2px; /* Slightly larger rounded corners */
  padding: 12px 24px; /* Increased padding for a more spacious look */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s; /* Smooth transitions */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Hover effect */
button:hover {
  background: linear-gradient(145deg, #FFB300, #b7d60bb6); /* Darker gradient on hover */
  transform: translateY(-3px); /* Slightly lift the button on hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}

/* Active state effect */
button:active {
  transform: translateY(0); /* Reset position when active */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow when active */
}

/* Optional: Responsive button text size */
@media (max-width: 600px) {
  button {
    font-size: 14px; /* Smaller font size on small screens */
    padding: 10px 20px; /* Adjust padding for small screens */
  }
}
