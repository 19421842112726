.cart-container {
    padding: 10px;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-top: -15px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cart-items {
    display: flex;
    flex-direction: column;
  }
  
  .cart-item {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
  }
  
  .cart-item-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 15px;
  }
  
  .cart-item-details {
    flex: 1;
    text-align: left;
  }
  
  .cart-item-price {
    font-weight: bold;
    color: #333;
    font-size: 12px;
    text-align: left;
  }
  
  .buy-now-button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 7px 10px;
    cursor: pointer;
  }
  
  .buy-now-button:hover {
    background-color: #218838;
  }
  