/* sciFiModal.css */

/* Container for the sci-fi themed modal */
.sci-fi-modal-container {
  background: #ffffff; /* Clean white background */
  border-radius: 12px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Enhanced glowing shadow effect */
  position: relative;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
  color: #333333;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-out;
}

/* Sci-fi modal title */
.sci-fi-modal-title {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #00bfff; /* Sci-fi blue */
  border-bottom: 2px solid #00bfff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Sci-fi modal content */
.sci-fi-modal-content {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #555555;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  box-shadow: inset 0 0 10px rgba(0, 191, 255, 0.1); /* Inner glow effect */
  overflow-y: auto;
  flex: 1;
}

/* Centered action buttons */
.sci-fi-modal-actions {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: center;
}

/* Action buttons with sci-fi glow */
.sci-fi-modal-button {
  color: #ffffff;
  background-color: #00bfff; /* Bright blue for sci-fi effect */
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 191, 255, 0.7); /* Glowing effect */
}

.sci-fi-modal-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 80%);
  transition: transform 0.4s ease;
  transform: translate(-50%, -50%) scale(0);
}

.sci-fi-modal-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.sci-fi-modal-button:hover {
  background-color: #1e90ff; /* Darker blue on hover */
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 191, 255, 0.9); /* Enhanced glowing effect */
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInSciFi {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutSciFi {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-30px);
    opacity: 0;
  }
}

.dialog-enter {
  animation: slideInSciFi 0.5s ease-out forwards;
}

.dialog-exit {
  animation: slideOutSciFi 0.5s ease-in forwards;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .sci-fi-modal-container {
    padding: 15px;
    max-width: 95%;
    max-height: 90vh;
  }

  .sci-fi-modal-title {
    font-size: 1.4rem;
  }

  .sci-fi-modal-content {
    font-size: 0.9rem;
  }

  .sci-fi-modal-actions {
    flex-direction: column;
    gap: 10px;
  }

  .sci-fi-modal-button {
    padding: 10px 18px;
    font-size: 0.9rem;
  }
}
